import React from "react";
import "../Styles/Modal.css";
import Lottie from "react-lottie";
import animationData from "../lotties/prescription.json";

export default function MedicineOrderAcceptance({ isOpen, onClose, children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!isOpen)  {
    return null;
  }

  const handleClose = () => {
    localStorage.setItem("medicineOrderPolicyAccepted", "true");
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Lottie options={defaultOptions} height={"90%"} width={"90%"} />
        <p
          style={{
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            color: "red",
          }}
        >
          {" "}
          We don't accept medicine orders without a valid prescription and we
          don't sell NRX medicine.
        </p>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#25D366",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              marginTop: "10px",
              zIndex: "1005",
              width: "80%",
            }}
            onClick={handleClose}
          >
            Acknowledge
          </button>
        </div>
      </div>
    </div>
  );
}
