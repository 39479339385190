import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
export default function PackagesDoc() {
  return (
    <div className="home-section">
      <Navbar />
      <div
        style={{
          width: "100%",
        }}
      >
        <iframe
          src="https://api.ganjean.com/media/package_files/Ganjean_Health_Health_Test_Brochure.pdf#toolbar=0&view=FitH"
          width={"100%"}
          height={"1000px"}
        />
      </div>
      <Footer />
    </div>
  );
}
