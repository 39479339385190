import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";
import Careers from "./Components/Careers";
import LabTest from "./Components/LabTestForm";
import MedicineOrder from "./Components/OrderMedicine";
import PackagesDoc from "./Pages/PackagesDoc";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import About from "./Components/About";
export const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/health/book-lab-test" element={<LabTest />} />
          <Route path="/health/order-medicine" element={<MedicineOrder />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/apply" element={<Appointment />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/packages-document" element={<PackagesDoc />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
