import React from "react";
import Doctor from "../Assets/Logo.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";
import test from "../Assets/testimg.png"
import Footer from "./Footer";
function About() {
  return (
    <>
      <div className="about-section" id="about">
        <div className="about-image-content">
          <img src={Doctor} alt="Doctor Group" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="about-title">
            <span>About Us</span>
          </h3>
          <p className="about-description">
            Ganjean Health, an e-pharmacy and telehealth platform, focuses on
            providing affordable medicines and Lab Tests. We focus on making
            essential medicines and Lab tests affordable and accessible through
            our proprietary algorithm & pan Kashmir reach, reducing monthly
            medical bills for chronic patients by 50% to 70%. Our platform also
            addresses patients' concerns around information about affordable
            medicines, the quality, and accessibility of these medicines, and
            empowers patients to make informed decisions before transitioning to
            such affordable 'substitute' medicines.
          </p>

          {/* <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Choose a Specialist"
          description="Find your perfect specialist and book with ease at Health Plus. Expert doctors prioritize your health, offering tailored care."
        />

        <SolutionStep
          title="Make a Schedule"
          description="Choose the date and time that suits you best, and let our dedicated team of medical professionals ensure your well-being with personalized care."
        />

        <SolutionStep
          title="Get Your Solutions"
          description="Our experienced doctors and specialists are here to provide expert advice and personalized treatment plans, helping you achieve your best possible health."
        /> */}
        </div>
      </div>

      <div className="about-section" id="about">
        <div className="about-text-content">
          <h3 className="about-title">
            <span>Our Vision</span>
          </h3>
          <p className="about-description">
            The vision is to make healthcare and Lab Tests affordable and
            accessible to all We are a technology driven, tele-health platform
            that aims to reduce healthcare expenses and improve healthcare
            outcomes by democratising medicine purchase and Lab tests in
            Kashmir.
          </p>
        </div>
        <div className="about-image-content">
          <img src={test} alt="Doctor Group" className="about-image1" />
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default About;
