import React from "react";
import "../Styles/Modal.css";
import Lottie from "react-lottie";
import animationData from "../lotties/agree.json";
export default function DataClearModal({ isOpen, onClose, children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!isOpen || localStorage.getItem("dataCleared")==="true") {
    return null;
  }

 
   const clearStorages = () => {
      // Clear local storage
      localStorage.clear();

      // Clear session storage
      sessionStorage.clear();

      // Clear cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Clear cache (limited to service workers and cache storage)
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });
      }
      onClose();
      localStorage.setItem("dataCleared", "true")
    };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Lottie options={defaultOptions} height={"50%"} width={"50%"} />
        <p style={{textAlign: "center", fontFamily: 'Poppins, sans-serif'}}> Welcome to Ganjean! </p>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#25D366",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              marginTop: "10px",
              zIndex: "1005",
              width: "80%",
            }}
            onClick={clearStorages}
          >
           Continue Browsing
          </button>
        </div>
      </div>
    </div>
  );
}
