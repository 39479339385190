import React, { useState, useEffect } from "react";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import { useSubmitApplication } from "./api";
import { useSearchParams } from "react-router-dom";
import "../Styles/BookAppointment.css";
import Loader from "./Loader";
function AppointmentForm() {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("job_id");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [resume, setResume] = useState(null);
  const [about, setAbout] = useState("NA");
  const [nearestEngagement, setNearestEngagement] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { mutate, isPending } = useSubmitApplication();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (phoneNumber === "") {
      errors.phoneNumber = "Phone number is required";
    }
    if (!address) {
      errors.address = "Address is required";
    }
    if (resume === null) {
      errors.resume = "Please select preferred mode";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("job", jobId);
    formData.append("name", name);
    formData.append("email", emailAddress);
    formData.append("phone_number", phoneNumber);
    formData.append("address", address);
    formData.append("resume", resume);
    formData.append("about", about);
    formData.append("nearest_engagement", nearestEngagement);

    // Submit form data
    mutate(formData);

    // Reset form fields and errors after successful submission
    setName("");
    setPhoneNumber("");
    setEmailAddress("");
    setAddress("");
    setResume(null);
    setAbout("NA");
    setNearestEngagement("");
    setFormErrors({});
  };

  return (
    <>
      <Navbar />
      {isPending && <Loader />}
      <div className={`appointment-form-section ${isPending ? "blurred" : ""}`}>
        <div className="form-container">
          <h2 className="form-title">
            <span>Ganjean Careers</span>
          </h2>

          <form className="form-content" onSubmit={handleSubmit}>
            <label>
              Full Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {formErrors.name && (
                <p className="error-message">{formErrors.name}</p>
              )}
            </label>
            <br />
            <label>
              Email Address:
              <input
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Phone Number:
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              {formErrors.phoneNumber && (
                <p className="error-message">{formErrors.phoneNumber}</p>
              )}
            </label>
            <br />

            <label>
              Address:
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              {formErrors.address && (
                <p className="error-message">{formErrors.address}</p>
              )}
            </label>
            <br />
            <label>
              When do you have your nearest exams?
              <input
                value={nearestEngagement}
                onChange={(e) => setNearestEngagement(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Resume:
              <input
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={(e) => setResume(e.target.files[0])}
                required
              />
              {formErrors.resume && (
                <p className="error-message">{formErrors.resume}</p>
              )}
            </label>

            <br />
            <button type="submit" className="text-appointment-btn">
              {isPending ? "Submitting..." : "Submit Application"}
            </button>
          </form>
        </div>

        <div className="legal-footer">
          <p>© {new Date().getFullYear} Ganjean+. All rights reserved.</p>
        </div>

        <ToastContainer autoClose={5000} limit={1} closeButton={false} />
      </div>
    </>
  );
}

export default AppointmentForm;
