import React, { useState, useEffect } from "react";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import { useGetAllLabTests } from "./api";
import Modal from "./Modal";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import Footer from "./Footer";
import SomethingWentWrong from "./SomethingWentWrongModal";
import Loader from "./Loader";
import { useLogRequest, useLogAddressRequest } from "./api";
function LabTest() {
  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading, isError: errorSubmitting } = useGetAllLabTests();
  const [isError, setIsError] = useState(false);
  const [location, setLocation] = useState({ lat: null, lon: null });

  const {mutate: logRequest, isPending: isLogPending} = useLogRequest();
  const {mutate: logAddressRequest, isPending: isAddressLogPending} = useLogAddressRequest();

  const onClose = () => {
    setIsOpen(false);
  };

  const onErrClose = () => {
    setIsError(false);
  };

const {mutate, isPending} = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://api.ganjean.com/api/health/test/",
        data
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("You test has been booked succesfully. Our agent will get in touch with you for confirmation soon. Thank you.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      setName("");
      setPhoneNumber("");
      setFormErrors({});
      setAddress("");
      setIsOpen(true);
    },
    onError: () => {
      toast.error("Failed to book test. Please try after sometime.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (
      phoneNumber === "" ||
      phoneNumber.length < 10 ||
      phoneNumber.length > 10 ||
      phoneNumber === null
    ) {
      errors.phoneNumber = "Phone number is required and must be 10 digits";
    }
    if (!address.trim()) {
      errors.address = "Address is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    const formData = {
      name: name,
      phone_number: phoneNumber,
      lat: location.lat,
      lon: location.lon,
      address: address
    };
    mutate(formData);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (err) => {

        }
      );
    }
  }, []);

  const handleLogRequest = () =>{
    const payload = {
      phoneNumber: phoneNumber,
      page: "labtest",
      location: location,
      timestamp: new Date().toISOString(),
    }
    logRequest(payload);
  }

  const handleLogAddressRequest = () =>{
    const payload = {
      phoneNumber: phoneNumber,
      address: address,
      location: location,
      timestamp: new Date().toISOString(),
    }
    logAddressRequest(payload);
  }

  return (
    <>
      <Navbar />
      {isPending && <Loader text={"Confirming your booking"} />}
      <div className={`appointment-form-section ${isPending ? 'blurred': ""}`}>
        <div className="form-container">
          <h2 className="form-title">
            <span>Book a Lab Test!</span>
          </h2>

          <form className="form-content" onSubmit={handleSubmit}>
            <label>
              Full Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {formErrors.name && (
                <p className="error-message">{formErrors.name}</p>
              )}
            </label>
            <br />
            <label>
              Phone Number:
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                onBlur={handleLogRequest}
              />
              {formErrors.phoneNumber && (
                <p className="error-message">{formErrors.phoneNumber}</p>
              )}
            </label>
            <br />
            <label>
              Address:
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                onBlur={handleLogAddressRequest}
              />
              {formErrors.address && (
                <p className="error-message">{formErrors.address}</p>
              )}
            </label>
            <br />
            <div className="d-flex">
            <button style={{height: "52px", width: "300px"}} type="submit" className="text-appointment-btn">
              {isPending ? "Booking..." : "Book Now"}
            </button>
            <button
              className="text-appointment-btn"
              style={{
                marginLeft: "5px",
                display: "flex",
                alignItems: "center",
                width: "300px",
                justifyContent: "center",
                backgroundColor:"#25D366"
              }}
              type="button"
              onClick={() => {
                window.open(
                  "https://wa.me/919090813814?text=I%20want%20to%20book%20test%20from%20Ganjean.%20Please%20assist%20me.",
                  "_blank"
                );
              }}
            >
              <svg
                style={{ marginRight: "8px" }}
                height={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="white"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
              </svg>{" "}
              Book on Whatsapp
            </button>
            </div>
          </form>
        </div>
        <ToastContainer autoClose={5000} limit={1} closeButton={false} />
      </div>
      <Modal isOpen={isOpen} onClose={onClose}/>
      <SomethingWentWrong isOpen={isError} onClose={onErrClose} />
      <Footer/>
    </>
  );
}

export default LabTest;
