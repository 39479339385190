import React from "react";
import Navbar from "../Components/Navbar";
import "../Styles/Policy.css";
import Footer from "../Components/Footer";
import { useGetTnC } from "../Components/api";
import Loader from "../Components/Loader";
export default function PrivacyPolicy() {
  const { data, isLoading } = useGetTnC();
  console.log("first, data", data);
  return (
    <>
      <Navbar />
      {isLoading && <Loader text={"Loading..."} />}
      <div className="main-div">
        <h2 className="form-title">
          <span>Privacy Policy</span>
        </h2>
        {data && (
          <div
            className="text-div"
            dangerouslySetInnerHTML={{
              __html: data.privacy,
            }}
          ></div>
        )}
      </div>
      <Footer />
    </>
  );
}
