import React from "react";
import "../Styles/Modal.css";
import Lottie from "react-lottie";
import animationData from "../lotties/something-went-wrong.json";
export default function SomethingWentWrong({ isOpen, onClose, children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!isOpen) {
    return null;
  }

 
   const handleClick = () => {
    window.open(
        "https://wa.me/919090813814?text=I%20need%20assistance%20with%20placing%20an%20order%20from%20Ganjean.",
        "_blank"
      );
    };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
        <p style={{textAlign: "center", fontFamily: 'Poppins, sans-serif', fontSize: "14px", color: "red"}}> Uh Oh! Something Went Wrong</p>
        <p style={{textAlign: "center", fontFamily: 'Poppins, sans-serif', fontSize: "14px", color: "#25D366", textDecoration: "underline"}}> Click below to book on Whatsapp</p>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#25D366",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              marginTop: "10px",
              zIndex: "1005",
              width: "80%",
            }}
            onClick={handleClick}
          >
           Book on WhatsApp!
          </button>
          <button
            style={{
              backgroundColor: "#fcbc04",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              marginTop: "10px",
              zIndex: "1005",
              width: "80%",
            }}
            onClick={onClose}
          >
           Close
          </button>
        </div>
      </div>
    </div>
  );
}
