import React from "react";
import animationData from "../lotties/loader.json";
import Lottie from "react-lottie";
import "../Styles/LoaderStyles.css";
export default function Loader({text}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loader-div">
      <div className="loader-content">
        <Lottie options={defaultOptions} height={"80%"} width={"80%"} />
        <p
        style={{
          textAlign: "center",
          fontFamily: "Poppins, sans-serif",
          fontSize: "20px",
          color: "#25D366",
          textDecoration: "underline",
        }}
      >
       {text}
      </p>
      </div>
    </div>
  );
}
