import React, {useState} from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Doctors from "../Components/Doctors";
import Footer from "../Components/Footer";
import AdCards from "../Components/AdCards";
import DataClearModal from "../Components/DataClearModal";
function Home() {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
  }
  return (
    <div className="home-section">
      <Navbar />
      {/* <Hero /> */}
      <AdCards />
      {/* <Info />
      <About />
      <BookAppointment />
      <Reviews />
      <Doctors /> */}
      <Footer />
      <DataClearModal isOpen={isOpen} onClose={onClose}/>
    </div>
  );
}

export default Home;
