import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { queryClient } from "../App";
import { toast } from "react-toastify";

export const useGetTnC = () => {
  return useQuery({
    queryKey: "tnc",
    queryFn: async () =>{
      const response = await axios.get("https://api.ganjean.com/api/home/privacy-policy")
      return response.data
    },
    onError: (error)=>{
      toast.error("Something went wrong")
    }
  })
}
export const useGetCareers = () => {
  return useQuery({
    queryKey: "careers",
    queryFn: async () => {
      const response = await axios.get("https://api.ganjean.com/api/work/jobs");
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData("careers", data);
    },
  });
};

export const useSubmitApplication = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://api.ganjean.com/api/work/apply/",
        data
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Application submitted successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: () => {
      toast.error("Failed to submit application", {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};

export const useGetAllLabTests = () => {
  return useQuery({
    queryKey: "labTests",
    queryFn: async () => {
      const response = await axios.get("https://api.ganjean.com/api/health/tests");
      return response.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData("labTests", data);
    },
  });
}

export const useBookLabTest = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://api.ganjean.com/api/health/test/",
        data
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("You test has been booked succesfully. Our agent will get in touch with you for confirmation soon. Thank you.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    },
    onError: () => {
      toast.error("Failed to book test. Please try after sometime.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    },
  });
}

export const useOrderMedicine = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://api.ganjean.com/api/health/medicine-order/",
        data
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Order placed succesfully. Our agent will get in touch with you for confirmation soon. Thank you.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    },
    onError: () => {
      toast.error("Failed to place an order. Please try again after sometime.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    },
  });
}

export const useLogRequest = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://ganjean-logs-service.onrender.com/log-request-for-safety",
        data
      );
      return response.data;
    },
  });
}


export const useLogAddressRequest = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        "https://ganjean-logs-service.onrender.com/log-request-for-better-experience",
        data
      );
      return response.data;
    },
  });
}
