import React from "react";
import InformationCard from "./InformationCard";
import {
  faTruckMedical,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import { useGetCareers } from "./api";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
export default function Careers() {
  const { data, error, isLoading } = useGetCareers();
  const navigate = useNavigate();
  const convertRichTextToPlainText = (richText) => {
    const div = document.createElement("div");
    div.innerHTML = richText;
    return div.textContent || div.innerText || "";
  };
  return (
    <>
      <Navbar />
      {isLoading && <Loader text={"Mmm! Loading something cool"}/>}
      <div className="info-section" id="services">
        <div className="info-cards-content">
          {data?.map((item) => (
            <InformationCard
              title={item.title}
              description={convertRichTextToPlainText(item.description)}
              icon={faTruckMedical}
              onClick={() => navigate(`/careers/apply?job_id=${item.id}`)}
            />
          ))}
        </div>
      </div>
    </>
  );
}
